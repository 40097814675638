import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

import welcomeBannerShapesIcon from '../images/shapes-icon.png';
import financialInsightsPlaceholderImage from '../images/financial-insights-placeholder.png';
import teamPicJim from '../images/team-pic-jim.png';
import teamPicJimLarge from '../images/team-pic-jim-large.png';
import teamPicSteve from '../images/team-pic-steve.png';
import teamPicDisha from '../images/team-pic-disha.png';
import teamPicSue from '../images/team-pic-sue.png';
import iconLinkedIn from '../images/icon-linkedin.png';
import iconInstagram from '../images/icon-instagram.png';

function DashboardPage(props) {

	// Welcome banner
	const [hideWelcomeMessage, setHideWelcomeMessage] = useState('');
	const welcomeBannerHide = (e) => {
		e.preventDefault();
		setHideWelcomeMessage('yes')
	}
	useEffect(() => {
		if(hideWelcomeMessage) {
			localStorage.setItem('hideWelcomeMessage', hideWelcomeMessage)
		}
	}, [hideWelcomeMessage]);


	// Documents
	const [latestDocuments, setLatestDocuments] = useState([]);
	const [latestDocumentsLoaded, setLatestDocumentsLoaded] = useState(0);
	const [newDocumentsCount, setNewDocumentsCount] = useState('');
	const [fileGeneratedDownloadLink, setFileGeneratedDownloadLink] = useState('');
	const [fileGeneratedDownloadTitle, setFileGeneratedDownloadTitle] = useState('');

	const getDocuments = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-latest-documents/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setLatestDocumentsLoaded(1);
				setLatestDocuments(response.data.documents);
				setNewDocumentsCount(response.data.count_documents_since_last_login);
			} else {
				alert('There was a problem retrieving the documents')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	const downloadFile = (fileId, fileName) => {
		setFileGeneratedDownloadTitle(fileName)
		const url = process.env.REACT_APP_REST_URL+'/request-download/&JWT='+props.jwt
		const formData = new FormData()
		formData.append('fileId', fileId)
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			setFileGeneratedDownloadLink(response.data.link+'&JWT='+props.jwt);
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	function downloadFileClicked() {
		setFileGeneratedDownloadLink('')
		setFileGeneratedDownloadTitle('')
	}


	// Jobs
	const [jobs, setJobs] = useState([]);
	const [jobInProgressCount, setJobInProgressCount] = useState('');
	const [jobDashboardCircleInProgressColor, setJobDashboardCircleInProgressColor] = useState(0);
	const [jobDashboardCircleInProgressDashArrayA, setJobDashboardCircleInProgressDashArrayA] = useState(0);
	const [jobDashboardCircleInProgressDashArrayB, setJobDashboardCircleInProgressDashArrayB] = useState(0);
	const [jobDashboardCircleInProgressDashOffset, setJobDashboardCircleInProgressDashOffset] = useState(0);

	const [jobDashboardCircleCompletedColor, setJobDashboardCircleCompletedColor] = useState(0);
	const [jobDashboardCircleCompletedDashArrayA, setJobDashboardCircleCompletedDashArrayA] = useState(0);
	const [jobDashboardCircleCompletedDashArrayB, setJobDashboardCircleCompletedDashArrayB] = useState(0);
	const [jobDashboardCircleCompletedDashOffset, setJobDashboardCircleCompletedDashOffset] = useState(0);

	const [jobDashboardCircleOverdueColor, setJobDashboardCircleOverdueColor] = useState(0);
	const [jobDashboardCircleOverdueDashArrayA, setJobDashboardCircleOverdueDashArrayA] = useState(0);
	const [jobDashboardCircleOverdueDashArrayB, setJobDashboardCircleOverdueDashArrayB] = useState(0);
	const [jobDashboardCircleOverdueDashOffset, setJobDashboardCircleOverdueDashOffset] = useState(0);
	const [jobDashboardCompletedPercentage, setJobDashboardCompletedPercentage] = useState(0);

	const getJobs = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-jobs/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setJobs(response.data.jobs);
				setJobInProgressCount(response.data.jobs.in_progress_count);
				setJobDashboardCircleInProgressColor('#FFE8CE');
				setJobDashboardCircleInProgressDashArrayA(response.data.jobs.in_progress_percentage);
				setJobDashboardCircleInProgressDashArrayB(100-response.data.jobs.in_progress_percentage);
				setJobDashboardCircleInProgressDashOffset(response.data.jobs.in_progress_percentage);

				setJobDashboardCircleCompletedColor('#9CF6C5');
				setJobDashboardCircleCompletedDashArrayA(response.data.jobs.completed_percentage);
				setJobDashboardCircleCompletedDashArrayB(100-response.data.jobs.completed_percentage);
				setJobDashboardCircleCompletedDashOffset(response.data.jobs.in_progress_percentage+response.data.jobs.completed_percentage);

				setJobDashboardCircleOverdueColor('#FFA59B');
				setJobDashboardCircleOverdueDashArrayA(response.data.jobs.overdue_percentage);
				setJobDashboardCircleOverdueDashArrayB(100-response.data.jobs.overdue_percentage);
				setJobDashboardCircleOverdueDashOffset(response.data.jobs.in_progress_percentage+response.data.jobs.completed_percentage+response.data.jobs.overdue_percentage);
				setJobDashboardCompletedPercentage(parseInt(response.data.jobs.completed_percentage));
			} else {
				alert('There was a problem retrieving the jobs')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}


	// Team
	const [shapesStaff, setShapesStaff] = useState([]);
	const [showTeamMember, setShowTeamMember] = useState('');

	const getShapesStaff = async () => {
		const url = process.env.REACT_APP_REST_URL+'/shapes-staff/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			setShapesStaff(response.data.staff);
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	// Onload
	useEffect(() => {
		getDocuments()
		getJobs()
		getShapesStaff()
		if(localStorage.getItem('hideWelcomeMessage')) {
			setHideWelcomeMessage(localStorage.getItem('hideWelcomeMessage'))
		}
	}, []);



	//https://codepen.io/donyo0682/pen/QoQzzw
  // var per1 = val1/total*100;
  // var per2 = val2/total*100;
  // var per3 = val3/total*100;
  // var offset = 25;
	const circle_inprogress = {
		transition: 'stroke 0.5s ease-in-out 0s, stroke-dasharray 0.5s ease-in-out 0s, stroke-dashoffset 0.5s ease-in-out 0s', 
		stroke: `${jobDashboardCircleInProgressColor}`,
		strokeDasharray: `${jobDashboardCircleInProgressDashArrayA}, ${jobDashboardCircleInProgressDashArrayB}`,
		strokeDashoffset: `${jobDashboardCircleInProgressDashOffset}`
  };
  // val1D.style.strokeDasharray = per1+" "+(100-per1);
  // val1D.style.strokeDashoffset = offset;
	const circle_completed = {
		transition: 'stroke 0.5s ease-in-out 0s, stroke-dasharray 0.5s ease-in-out 0s, stroke-dashoffset 0.5s ease-in-out 0s', 
		stroke: `${jobDashboardCircleCompletedColor}`,
		strokeDasharray: `${jobDashboardCircleCompletedDashArrayA}, ${jobDashboardCircleCompletedDashArrayB}`,
		strokeDashoffset: `${jobDashboardCircleCompletedDashOffset}`
  };
  // val2D.style.strokeDasharray = per2+" "+(100-per2);
  // val2D.style.strokeDashoffset = 100-per1+offset;
	const circle_overdue = {
		transition: 'stroke 0.5s ease-in-out 0s, stroke-dasharray 0.5s ease-in-out 0s, stroke-dashoffset 0.5s ease-in-out 0s', 
		stroke: `${jobDashboardCircleOverdueColor}`,
		strokeDasharray: `${jobDashboardCircleOverdueDashArrayA}, ${jobDashboardCircleOverdueDashArrayB}`,
		strokeDashoffset: `${jobDashboardCircleOverdueDashOffset}`
  };
  // val3D.style.strokeDasharray = per3+" "+(100-per3);
  // val3D.style.strokeDashoffset = 100-(per1+per2)+offset;

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="dashboard" />
			<div className="appContent">

				{hideWelcomeMessage !== 'yes'? (
					<div className="appContentRow">
						<div className="welcomeBanner">
							<Link className="welcomeBannerDismiss" onClick={welcomeBannerHide} to="#">x</Link>
							<img src={welcomeBannerShapesIcon} className="welcomeBannerIcon" />
							<h1>Welcome to your Shapes portal, {props.userDetails.first_name}!</h1>
							<Link className="welcomeBannerTourLink" to="#"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_d_268_2582)">
								<path d="M4.61143 1.06868C4.59547 0.351197 5.31808 -0.149032 5.984 0.118518L14.5266 3.55072C15.2657 3.84769 15.3779 4.84747 14.723 5.30085L6.38521 11.0727C5.73028 11.526 4.834 11.069 4.81628 10.2727L4.61143 1.06868Z" fill="currentColor"/>
								</g>
								<defs>
								<filter id="filter0_d_268_2582" x="0.611084" y="0.0453796" width="18.5427" height="19.2067" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
								<feFlood flood-opacity="0" result="BackgroundImageFix"/>
								<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
								<feOffset dy="4"/>
								<feGaussianBlur stdDeviation="2"/>
								<feComposite in2="hardAlpha" operator="out"/>
								<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
								<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_268_2582"/>
								<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_268_2582" result="shape"/>
								</filter>
								</defs>
								</svg>Get a tour
								</Link>
						</div>
					</div>
				):('')}

				<div className="dashboardTop">
					<Link className="dashboardLink dashboardLinkDocuments" to="/documents">
						<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.95314 7.98509e-08C1.43514 7.98509e-08 0.938352 0.205775 0.57207 0.572057C0.205788 0.938339 1.32253e-05 1.43512 1.32253e-05 1.95313V6.875H0.00126321C0.000334964 6.90103 -8.17666e-05 6.92708 1.32253e-05 6.95312V15.5794C1.32253e-05 16.0974 0.205788 16.5942 0.57207 16.9604C0.938352 17.3267 1.43514 17.5325 1.95314 17.5325H16.7969C17.3149 17.5325 17.8117 17.3267 18.178 16.9604C18.5442 16.5942 18.75 16.0974 18.75 15.5794V6.95312C18.75 6.92688 18.75 6.90063 18.7481 6.875H18.75V4.46063C18.75 3.94262 18.5442 3.44584 18.178 3.07956C17.8117 2.71328 17.3149 2.5075 16.7969 2.5075H9.92939C9.74314 2.5075 9.56439 2.43375 9.43251 2.30188L7.88439 0.755C7.64512 0.51557 7.361 0.325651 7.04829 0.196102C6.73557 0.0665532 6.40038 -8.42033e-05 6.06189 7.98509e-08H1.95314ZM17.5 5.13062C17.2757 5.04408 17.0373 4.99979 16.7969 5H1.95314C1.7127 4.99968 1.47429 5.04397 1.25001 5.13062V1.95313C1.25001 1.565 1.56501 1.25 1.95314 1.25H6.06189C6.41439 1.25 6.75189 1.39 7.00064 1.63875L8.54876 3.18625C8.91501 3.55187 9.41126 3.7575 9.93001 3.7575H16.7969C17.1856 3.7575 17.5 4.0725 17.5 4.46063V5.13062ZM1.25001 6.95312C1.25001 6.565 1.56501 6.25 1.95314 6.25H16.7969C17.1856 6.25 17.5 6.565 17.5 6.95312V15.5794C17.5 15.9681 17.1856 16.2825 16.7969 16.2825H1.95314C1.76666 16.2825 1.58782 16.2084 1.45595 16.0766C1.32409 15.9447 1.25001 15.7659 1.25001 15.5794V6.95312Z" fill="#101010"/>
						</svg>
						<span className="count">{newDocumentsCount !== '' ? newDocumentsCount : ( <div className="loader"></div> ) }</span>
						<span className="label">New Document{newDocumentsCount === 1 ? '' : 's'}</span>
						<span className="viewLink">view</span>
					</Link>
					<Link className="dashboardLink dashboardLinkJobs" to="/jobs">
						<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.625 1.2H16.5C16.5569 1.2 16.6039 1.22185 16.6352 1.25169C16.6661 1.28108 16.675 1.31041 16.675 1.33333V6.33333C16.675 6.35626 16.6661 6.38559 16.6352 6.41498C16.6039 6.44482 16.5569 6.46667 16.5 6.46667H1.625C1.56808 6.46667 1.52112 6.44482 1.48979 6.41498C1.45893 6.38559 1.45 6.35626 1.45 6.33333V1.33333C1.45 1.31041 1.45893 1.28108 1.48979 1.25169C1.52112 1.22185 1.56808 1.2 1.625 1.2ZM1.625 9.53333H16.5C16.5569 9.53333 16.6039 9.55518 16.6352 9.58502C16.6661 9.61441 16.675 9.64374 16.675 9.66667V14.6667C16.675 14.6896 16.6661 14.7189 16.6352 14.7483C16.6039 14.7782 16.5569 14.8 16.5 14.8H1.625C1.56808 14.8 1.52112 14.7782 1.48979 14.7483C1.45893 14.7189 1.45 14.6896 1.45 14.6667V9.66667C1.45 9.64374 1.45893 9.61441 1.48979 9.58502C1.52112 9.55518 1.56808 9.53333 1.625 9.53333Z" stroke="#101010" stroke-width="1.4"/>
						</svg>
						<span className="count">{jobInProgressCount !== '' ? jobInProgressCount : ( <div className="loader"></div> ) }</span>
						<span className="label">Job{jobs.in_progress_count === 1 ? '' : 's'} in progress</span>
						<span className="viewLink">view</span>
					</Link>
					<div className="dashboardContact">
						<p>Contact the Shapes team</p>
						<Link className="dashboardContactPhone" to="tel:01227696131">01227 696131</Link>
						<Link className="dashboardContactBookMeetingLink">Book a meeting</Link>
					</div>
				</div>

				<div className="appContentRow appContentRow2">
					<div className="appBox">
						<div className="appBoxHeader">
							<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_268_2589)">
								<path d="M3.24618 3.7H17.4128C17.4846 3.7 17.5462 3.7616 17.5462 3.83333V8.83333C17.5462 8.90507 17.4846 8.96667 17.4128 8.96667H3.24618C3.17444 8.96667 3.11284 8.90507 3.11284 8.83333V3.83333C3.11284 3.7616 3.17444 3.7 3.24618 3.7ZM3.24618 12.0333H17.4128C17.4846 12.0333 17.5462 12.0949 17.5462 12.1667V17.1667C17.5462 17.2384 17.4846 17.3 17.4128 17.3H3.24618C3.17444 17.3 3.11284 17.2384 3.11284 17.1667V12.1667C3.11284 12.0949 3.17444 12.0333 3.24618 12.0333Z" stroke="#101010" stroke-width="1.4"/>
								</g>
								<defs>
								<clipPath id="clip0_268_2589">
								<rect width="20" height="20" fill="white" transform="translate(0.746094 0.5)"/>
								</clipPath>
								</defs>
								</svg>
							<h4>Jobs</h4>
							<Link className="viewLink" to="#">view all</Link>
						</div>
						<div className="appBoxContent appBoxContentDashboardJobs">
							<div className="dashboardJobs">
								{jobs.all_count === 0 ? (
								<>
								<p className="noJobsAdded">We haven't set up any jobs yet. Please come back soon.</p>
								</>
								):(
								<>
								<div className="dashboardJobsDonut">
									<svg class="donut" width="215" height="100%" viewBox="0 0 42 42">
										<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
										<circle id="donut-segment1" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-dasharray="0 0" stroke-dashoffset="25" style={circle_completed}></circle>
										<circle id="donut-segment2" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-dasharray="0 0" stroke-dashoffset="25" style={circle_inprogress}></circle>
										<circle id="donut-segment3" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-dasharray="0 0" stroke-dashoffset="25" style={circle_overdue}></circle>
									</svg>
									<span className="percentage">{jobInProgressCount ? jobDashboardCompletedPercentage+'%' : ( <div className="loader"></div> ) }</span>
								</div>
								<div className="dashboardJobsKey">
									<div className="dashboardJobsKeyItem dashboardJobsKeyItemCompleted"><span></span>Completed</div>
									<div className="dashboardJobsKeyItem dashboardJobsKeyItemInProgress"><span></span>In Progress</div>
									<div className="dashboardJobsKeyItem dashboardJobsKeyItemOverdue"><span></span>Overdue</div>
								</div>
								</>
								)}
							</div>
						</div>
					</div>
					<div className="appBox">
						<div className="appBoxHeader">
							<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.95314 0.733765C1.43514 0.733765 0.938352 0.93954 0.57207 1.30582C0.205788 1.6721 1.32253e-05 2.16889 1.32253e-05 2.68689V7.60876H0.00126321C0.000334964 7.6348 -8.17666e-05 7.66084 1.32253e-05 7.68689V16.3131C1.32253e-05 16.8311 0.205788 17.3279 0.57207 17.6942C0.938352 18.0605 1.43514 18.2663 1.95314 18.2663H16.7969C17.3149 18.2663 17.8117 18.0605 18.178 17.6942C18.5442 17.3279 18.75 16.8311 18.75 16.3131V7.68689C18.75 7.66064 18.75 7.63439 18.7481 7.60876H18.75V5.19439C18.75 4.67639 18.5442 4.1796 18.178 3.81332C17.8117 3.44704 17.3149 3.24126 16.7969 3.24126H9.92939C9.74314 3.24126 9.56439 3.16751 9.43251 3.03564L7.88439 1.48876C7.64512 1.24933 7.361 1.05942 7.04829 0.929867C6.73557 0.800318 6.40038 0.73368 6.06189 0.733765H1.95314ZM17.5 5.86439C17.2757 5.77785 17.0373 5.73356 16.7969 5.73376H1.95314C1.7127 5.73344 1.47429 5.77774 1.25001 5.86439V2.68689C1.25001 2.29876 1.56501 1.98376 1.95314 1.98376H6.06189C6.41439 1.98376 6.75189 2.12376 7.00064 2.37251L8.54876 3.92001C8.91501 4.28564 9.41126 4.49126 9.93001 4.49126H16.7969C17.1856 4.49126 17.5 4.80626 17.5 5.19439V5.86439ZM1.25001 7.68689C1.25001 7.29876 1.56501 6.98376 1.95314 6.98376H16.7969C17.1856 6.98376 17.5 7.29876 17.5 7.68689V16.3131C17.5 16.7019 17.1856 17.0163 16.7969 17.0163H1.95314C1.76666 17.0163 1.58782 16.9422 1.45595 16.8103C1.32409 16.6785 1.25001 16.4996 1.25001 16.3131V7.68689Z" fill="#101010"/>
								</svg>
							<h4>Latest Documents</h4>
							<Link className="viewLink" to="/documents">view all</Link>
						</div>
						<div className="appBoxContent">
							{latestDocuments?.length > 0 ? (
							<div className="dashboardDocuments">
								{latestDocuments.map((latestDocument) => (
								<Link to="#" onClick={() => downloadFile(latestDocument.fileId, latestDocument.fileName)} className="dashboardDocumentsItem">{latestDocument.fileName}</Link>
								))}
							</div>
							):(
							<>
							{latestDocumentsLoaded ? (
							<>
							<p className="noDocumentsAdded">There are currently no documents uploaded.</p>
							</>
							):(
							<div className="loader">Loading</div>
							)}
							</>
							)}
						</div>
					</div>
				</div>

				<div className="appContentRow">
					<div className="appBox">
						<div className="appBoxHeader">
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.375 12.875H3.625V6.75H5.375V12.875ZM8.875 12.875H7.125V4.125H8.875V12.875ZM12.375 12.875H10.625V9.375H12.375V12.875ZM14.125 14.625H1.875V2.375H14.125V14.625ZM14.125 0.625H1.875C0.9125 0.625 0.125 1.4125 0.125 2.375V14.625C0.125 15.5875 0.9125 16.375 1.875 16.375H14.125C15.0875 16.375 15.875 15.5875 15.875 14.625V2.375C15.875 1.4125 15.0875 0.625 14.125 0.625Z" fill="black"/>
								</svg>
							<h4>Financial Insights</h4>
							<Link className="viewLink" to="#">find out more</Link>
						</div>
						<div className="appBoxContent">
							<div className="dashboardFinancialInsightsPlaceholder">
								<img src={financialInsightsPlaceholderImage} />
								<p>Unlock detailed financial insights with Syft Analytics<br/>Ask the Shapes Team for a demo</p>
							</div>
						</div>
					</div>
				</div>

				<div className="appContentRow">
					<div className="dashboardTeam">
						<h2>Your Shapes team!</h2>
						<div className="dashboardTeamMembers">
							{shapesStaff?.length > 0 ? (
							<>
							{shapesStaff.map((shapesStaffMember) => (
							<div className="dashboardTeamMember">
								<Link className="dashboardTeamMemberLink" onClick={(e) => setShowTeamMember(shapesStaffMember.name)} to="#">
									<span className="dashboardTeamMemberPic"><img src={"data:image/png;base64,"+shapesStaffMember.image} /></span>
									<span className="dashboardTeamMemberName">{shapesStaffMember.name}</span>
								</Link>
							</div>
							))}
							</>
							):('')}
						</div>
					</div>
				</div>

				{showTeamMember !== '' ? (
				<>
				<div className="modalBack active" onClick={() => setShowTeamMember('')}></div>
				<div className="modalWindow active">
					<div className="modalWindowInner animated fadeInUp">
						<Link to="#" className="modalWindowClose" onClick={() => setShowTeamMember('')}></Link>
						<div className="modalWindowContent modalTeamMember">
							{shapesStaff.map((shapesStaffMember) => (
							<>
							{showTeamMember === shapesStaffMember.name ? (
							<>
							<div className="modalTeamMemberHeader">
								<h3>{shapesStaffMember.name}</h3>
								<p>{shapesStaffMember.position}</p>
							</div>
							<div className="modalTeamMemberContent">
								<div className="modalTeamMemberContentPic"><img src={"data:image/png;base64,"+shapesStaffMember.image} /></div>
								<div className="modalTeamMemberContentProfile">
									<p>{shapesStaffMember.bio}</p>
									{shapesStaffMember.book_call_link ? (
									<>
									<p><Link target="_blank" to={shapesStaffMember.book_call_link}>Book a call</Link></p>
									</>
									):('')}
								</div>
							</div>
							<div className="modalTeamMemberSocials">
								{shapesStaffMember.linkedin_link ? (
								<>
								<Link target="_blank" to={shapesStaffMember.linkedin_link}><img src={iconLinkedIn} /></Link>
								</>
								):('')}
								{shapesStaffMember.instagram_link ? (
								<>
								<Link target="_blank" to={shapesStaffMember.instagram_link}><img src={iconInstagram} /></Link>
								</>
								):('')}
							</div>
							</>
							):('')}
							</>
							))}
						</div>
					</div>
				</div>
				</>
				) : ('')}

				{fileGeneratedDownloadTitle ? (
				<>
				<div className="modalBack active" onClick={() => downloadFileClicked()}></div>
				<div className="modalWindow active">
					<div className="modalWindowInner animated fadeInUp">
						<Link to="#" className="modalWindowClose" onClick={() => downloadFileClicked()}></Link>
						<div className="modalWindowContent modalDownload">
							<div className="modalDownloadHeader">
								<h3>{fileGeneratedDownloadTitle}</h3>
							</div>
							<div className="modalDownloadContent">
								{fileGeneratedDownloadLink ? (
								<>
								<p>Your file is ready to download.</p>
								<Link to={fileGeneratedDownloadLink} onClick={(e) => downloadFileClicked()} className="button" target="_blank">Download now</Link>
								</>
								):(
								<>
								<p>Please wait while we generate your download</p>
								<div className="loader"></div>
								</>
								)}
							</div>
						</div>
					</div>
				</div>
				</>
				):('')}

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default DashboardPage