import React, { useState, useEffect } from "react";
import logo from '../images/logo.svg';
import { Link } from "react-router-dom";

export default function Header(props) {

	const [showUserProfile, setShowUserProfile] = useState('');
	const [showCompanyProfile, setShowCompanyProfile] = useState('');

	function logUserOut(e) {
	    e.preventDefault();
	    props.logUserOut();
	}
	return (
	<>
	<div className={"appHeader appHeader-loggedIn-" + props.isLoggedIn}>
		<div className="container">
			<img src={logo} className="logoImage" />
			<ul className="appHeaderNav">
			{props.isLoggedIn == 1 ? (
				<>
				<li className="appHeaderNavLinkHasDropdown">
					<Link className="appHeaderNavLink" to="/">{props.userDetails.first_name} {props.userDetails.last_name}</Link>
					<ul>
						<li><Link className="appHeaderNavLink" to="#" onClick={() => setShowUserProfile(1)}>My profile</Link></li>
						<li><Link className="appHeaderNavLink" to="#" onClick={() => setShowCompanyProfile(1)}>Company info</Link></li>
						<li><Link className="appHeaderNavLink" onClick={logUserOut} to="/">Logout</Link></li>
					</ul>
				</li>
				</>
		    ):(
				<li><Link className="appHeaderNavLink" to="https://shapes.team/">back to main site</Link></li>
		    )}
			</ul>
			<div className="clearfix"></div>
		</div>
	</div>

	{showUserProfile !== '' ? (
	<>
	<div className="modalBack active" onClick={() => setShowUserProfile('')}></div>
	<div className="modalWindow active">
		<div className="modalWindowInner animated fadeInUp">
			<Link to="#" className="modalWindowClose" onClick={() => setShowUserProfile('')}></Link>
			<div className="modalWindowContent modalUserProfile">
				<div className="modalUserProfileHeader">
					<h3>{props.userDetails.first_name} {props.userDetails.last_name}</h3>
				</div>
				<div className="modalUserProfileContent">
					<div className="modalUserProfileItem"><span>Email</span> {props.userDetails.email}</div>
					<div className="modalUserProfileItem"><span>Mobile Phone</span> {props.userDetails.mobile_phone}</div>
				</div>
			</div>
		</div>
	</div>
	</>
	) : ('')}

	{showCompanyProfile !== '' ? (
	<>
	<div className="modalBack active" onClick={() => setShowCompanyProfile('')}></div>
	<div className="modalWindow active">
		<div className="modalWindowInner animated fadeInUp">
			<Link to="#" className="modalWindowClose" onClick={() => setShowCompanyProfile('')}></Link>
			<div className="modalWindowContent modalUserProfile">
				<div className="modalUserProfileHeader">
					<h3>{props.userDetails.company_name}</h3>
				</div>
				<div className="modalUserProfileContent">
					<div className="modalUserProfileItem"><span>Main Contact Email</span> {props.userDetails.company_email}</div>
					<div className="modalUserProfileItem"><span>Postal Address</span> {props.userDetails.company_address}</div>
					<div className="modalUserProfileItem"><span>Company number</span> {props.userDetails.company_number}</div>
				</div>
			</div>
		</div>
	</div>
	</>
	) : ('')}

	</>
	)
}