import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

import logoXero from '../images/logo-xero.png'
import logoHubDoc from '../images/logo-hubdoc.png'
import logoMonday from '../images/logo-monday.png'

import iconDownload from '../images/icon-download.png'
import iconExternal from '../images/external-link.png'

function AppsPage(props) {

	const [companyApps, setCompanyApps] = useState([]);
	const [companyLinks, setCompanyLinks] = useState([]);

	const getCompanyApps = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-company-apps/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			setCompanyApps(response.data.apps)
			setCompanyLinks(response.data.links)
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	// Onload
	useEffect(() => {
		getCompanyApps()
	}, []);

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="apps" />
			<div className="appContent">

				<div className="appContentHeader">
					<h1>Apps & Links</h1>
					<p>Easy access to all your regular links and apps used with Shapes </p>
				</div>

				{companyApps?.length === 0 && companyLinks?.length === 0 ? (
				<div className="loader"></div>
				):('')}

				<div className="appTiles">
				{companyApps?.length > 0 ? (
					<>
					{companyApps.map((companyApp) => (
					<Link to={companyApp.link} target="_blank" className="appTile">
						<span className="appTileLogo"><img src={"data:image/png;base64,"+companyApp.logo} /></span>
						<h4>{companyApp.name}</h4>
						<span className="appTileDescription">{companyApp.description}</span>
					</Link>
					))}
					</>
				):('')}
				</div>

				{companyLinks?.length > 0 ? (
				<div className="linksList">
					<h2>Additional Links & Documents</h2>
					{companyLinks.map((companyLink) => (
					<Link className="linksListItem" to={companyLink.link} target="_blank">
						<span className="linksListItemTitle">{companyLink.name}</span>
						<span className="linksListItemDescription">{companyLink.description}</span>
						{companyLink.type === 'file' ? (
						<>
						<img src={iconDownload} />
						</>
						):(
						<>
						<img src={iconExternal} />
						</>
						)}
					</Link>
					))}
				</div>
				):('')}

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default AppsPage