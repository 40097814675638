import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

import logoEcologi from '../images/logo-ecologi.png'

function ReferralsPage(props) {

	//https://codepen.io/donyo0682/pen/QoQzzw
	// var per1 = val1/total*100;
	// var per2 = val2/total*100;
	// var per3 = val3/total*100;
	// var offset = 25;
	const circle_inprogress = {
		transition: 'stroke 0.5s ease-in-out 0s, stroke-dasharray 0.5s ease-in-out 0s, stroke-dashoffset 0.5s ease-in-out 0s', 
		stroke: '#FFE8CE',
		strokeDasharray: `${props.userDetails.referrals_in_progress_percentage}, ${100 - props.userDetails.referrals_in_progress_percentage}`,
		strokeDashoffset: `${props.userDetails.referrals_in_progress_percentage}`
  };
  // val1D.style.strokeDasharray = per1+" "+(100-per1);
  // val1D.style.strokeDashoffset = offset;
	const circle_completed = {
		transition: 'stroke 0.5s ease-in-out 0s, stroke-dasharray 0.5s ease-in-out 0s, stroke-dashoffset 0.5s ease-in-out 0s', 
		stroke: '#9CF6C5',
		strokeDasharray: `${props.userDetails.referrals_completed_percentage}, ${100 - props.userDetails.referrals_completed_percentage}`,
		strokeDashoffset: `${props.userDetails.referrals_in_progress_percentage+props.userDetails.referrals_completed_percentage}`
  };

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="referrals" />
			<div className="appContent">

				<div className="appContentHeader">
					<h1>Shapes referral scheme</h1>
				</div>

				<div className="referralProcess">
					<div className="referralProcessStep referralProcessStep1 animated fadeInUp animated-delay-400">
						<span className="referralProcessStepArrow animated fadeInLeft animated-delay-800"></span>
						<h3>1</h3>
						<p>Refer us to someone via email and copy us in as way of an introduction to Shapes</p>
					</div>
					<div className="referralProcessStep referralProcessStep2 animated fadeInUp animated-delay-1200">
						<span className="referralProcessStepArrow animated fadeInLeft animated-delay-1600"></span>
						<h3>2</h3>
						<p>We’ll start a relationship with them and explore where we can help their business</p>
					</div>
					<div className="referralProcessStep referralProcessStep3 animated fadeInUp animated-delay-2000">
						<h3>3</h3>
						<p>Once they’ve worked with ys for 6+ months - we give you a nice reward!</p>
					</div>
				</div>

				<div className="referralWidgets">
					<div className="referralWidgetsLeft">
						<div className="appBox">
							<div className="appBoxHeader">
								<h4>Referrals made to Shapes</h4>
							</div>
							<div className="appBoxContent">
								<div className="dashboardJobs">
									<div className="dashboardJobsDonut">
										<svg class="donut" width="215" height="100%" viewBox="0 0 42 42">
											<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
											<circle id="donut-segment2" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-dasharray="0 0" stroke-dashoffset="25" style={circle_inprogress}></circle>
											<circle id="donut-segment3" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-dasharray="0 0" stroke-dashoffset="25" style={circle_completed}></circle>
										</svg>
										<span className="percentage">{props.userDetails.referrals_count}</span>
									</div>
									<div className="dashboardJobsKey">
										<div className="dashboardJobsKeyItem dashboardJobsKeyItemCompleted"><span></span>Successful</div>
										<div className="dashboardJobsKeyItem dashboardJobsKeyItemInProgress"><span></span>In Progress</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="referralWidgetsRight">
						<div className="appBox">
							<div className="appBoxHeader">
								<h4>Value you’ve earnt back from us</h4>
							</div>
							<div className="appBoxContent">
								<div className="referralValue">
									<div className="referralValueAmount">£{props.userDetails.referrals_value}</div>
									<div className="referralValueLabel">
									{props.userDetails.referrals_value_since !== '' ? (
									<>
									since {props.userDetails.referrals_value_since}
									</>
									):('')}</div>
								</div>
							</div>
						</div>
						<div className="appBox">
							<div className="appBoxHeader">
								<h4>🌳 &nbsp;Trees we’ve planted because of your business</h4>
							</div>
							<div className="appBoxContent">
								<div className="referralTreesPlanted">
									<div className="referralTreesPlantedAmount">{props.userDetails.trees_planted}</div>
									<div className="referralTreesPlantedLabel">trees planted!</div>
									<Link to="https://ecologi.com/shapes?r=64b95eac98e7620f9eb6f0f9" target="_blank"><img src={logoEcologi} /></Link>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default ReferralsPage