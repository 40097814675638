import defaultCompanyPic from '../images/default-company-pic.jpg';
import externalLinkIcon from '../images/external-link.png';
import { Link } from "react-router-dom";

export default function Sidebar(props) {
	function logUserOut(e) {
	    e.preventDefault();
	    props.logUserOut();
	}
	return (
		<div className={"appSidebar appSidebar-loggedIn-" + props.isLoggedIn}>
			<div className="appSidebarCompanyName">
				{props.userDetails.company_logo ? (
				<div className="companyLogo"><img src={"data:image/png;base64,"+props.userDetails.company_logo} /></div>
				):(
				<div className="companyLogo"><img src={defaultCompanyPic} /></div>
				)}
				{props.userDetails.company_name}
			</div>
			<ul className={"appSidebarNav appSidebarNav-current-" + props.currentPage}>
				<li><Link className="appSidebarNavItem appSidebarNavItemDashboard" to="/"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_452_147)">
					<path d="M3.5 10.6333C3.33431 10.6333 3.2 10.499 3.2 10.3333V4C3.2 3.83431 3.33431 3.7 3.5 3.7H8.16667C8.33235 3.7 8.46667 3.83431 8.46667 4V10.3333C8.46667 10.499 8.33235 10.6333 8.16667 10.6333H3.5ZM3.5 17.3C3.33431 17.3 3.2 17.1657 3.2 17V14C3.2 13.8343 3.33431 13.7 3.5 13.7H8.16667C8.33235 13.7 8.46667 13.8343 8.46667 14V17C8.46667 17.1657 8.33235 17.3 8.16667 17.3H3.5ZM11.8333 17.3C11.6676 17.3 11.5333 17.1657 11.5333 17V10.6667C11.5333 10.501 11.6676 10.3667 11.8333 10.3667H16.5C16.6657 10.3667 16.8 10.501 16.8 10.6667V17C16.8 17.1657 16.6657 17.3 16.5 17.3H11.8333ZM11.5333 4C11.5333 3.83431 11.6676 3.7 11.8333 3.7H16.5C16.6657 3.7 16.8 3.83431 16.8 4V7C16.8 7.16569 16.6657 7.3 16.5 7.3H11.8333C11.6676 7.3 11.5333 7.16569 11.5333 7V4Z" stroke="#101010" stroke-width="1.4"/>
					</g>
					<defs>
					<clipPath id="clip0_452_147">
					<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
					</clipPath>
					</defs>
					</svg>Dashboard</Link></li>
				<li><Link className="appSidebarNavItem appSidebarNavItemJobs" to="/jobs"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_452_156)">
					<path d="M2.50008 3.7H16.6667C16.7385 3.7 16.8001 3.7616 16.8001 3.83333V8.83333C16.8001 8.90507 16.7385 8.96667 16.6667 8.96667H2.50008C2.42835 8.96667 2.36675 8.90507 2.36675 8.83333V3.83333C2.36675 3.7616 2.42835 3.7 2.50008 3.7ZM2.50008 12.0333H16.6667C16.7385 12.0333 16.8001 12.0949 16.8001 12.1667V17.1667C16.8001 17.2384 16.7385 17.3 16.6667 17.3H2.50008C2.42835 17.3 2.36675 17.2384 2.36675 17.1667V12.1667C2.36675 12.0949 2.42835 12.0333 2.50008 12.0333Z" stroke="#101010" stroke-width="1.4"/>
					</g>
					<defs>
					<clipPath id="clip0_452_156">
					<rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
					</clipPath>
					</defs>
					</svg>Jobs</Link></li>
				<li><Link className="appSidebarNavItem appSidebarNavItemDocuments" to="/documents"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2.57814 2.375C2.06014 2.375 1.56335 2.58078 1.19707 2.94706C0.830788 3.31334 0.625013 3.81012 0.625013 4.32813V9.25H0.626263C0.625335 9.27603 0.624918 9.30208 0.625013 9.32812V17.9544C0.625013 18.4724 0.830788 18.9692 1.19707 19.3354C1.56335 19.7017 2.06014 19.9075 2.57814 19.9075H17.4219C17.9399 19.9075 18.4367 19.7017 18.803 19.3354C19.1692 18.9692 19.375 18.4724 19.375 17.9544V9.32812C19.375 9.30188 19.375 9.27563 19.3731 9.25H19.375V6.83563C19.375 6.31762 19.1692 5.82084 18.803 5.45456C18.4367 5.08828 17.9399 4.8825 17.4219 4.8825H10.5544C10.3681 4.8825 10.1894 4.80875 10.0575 4.67688L8.50939 3.13C8.27012 2.89057 7.986 2.70065 7.67329 2.5711C7.36057 2.44155 7.02538 2.37492 6.68689 2.375H2.57814ZM18.125 7.50562C17.9007 7.41908 17.6623 7.37479 17.4219 7.375H2.57814C2.3377 7.37468 2.09929 7.41897 1.87501 7.50562V4.32813C1.87501 3.94 2.19001 3.625 2.57814 3.625H6.68689C7.03939 3.625 7.37689 3.765 7.62564 4.01375L9.17376 5.56125C9.54001 5.92687 10.0363 6.1325 10.555 6.1325H17.4219C17.8106 6.1325 18.125 6.4475 18.125 6.83563V7.50562ZM1.87501 9.32812C1.87501 8.94 2.19001 8.625 2.57814 8.625H17.4219C17.8106 8.625 18.125 8.94 18.125 9.32812V17.9544C18.125 18.3431 17.8106 18.6575 17.4219 18.6575H2.57814C2.39166 18.6575 2.21282 18.5834 2.08095 18.4516C1.94909 18.3197 1.87501 18.1409 1.87501 17.9544V9.32812Z" fill="#101010"/>
					</svg>Documents</Link></li>
				<li><Link className="appSidebarNavItem appSidebarNavItemApps" to="/apps"><svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1.5" y="1" width="17" height="10" rx="2.5" stroke="black"/>
					<rect x="1.5" y="13" width="17" height="3" rx="1.5" stroke="black"/>
					</svg>My Apps & Links</Link></li>
			</ul>
			<ul className={"appSidebarAdditionalNav appSidebarAdditionalNav-current-" + props.currentPage}>
				<li><Link className="appSidebarAdditionalNavItem" to="tel:01227696131"><span>☎️</span>01227 696131</Link></li>
				<li><Link className="appSidebarAdditionalNavItem appSidebarAdditionalNavItemReferrals" to="/referrals"><span>📣</span>Make a referral</Link></li>
				<li><Link className="appSidebarAdditionalNavItem" to="https://shapes.team" target="_blank"><span>👨‍💻</span>Shapes website <img src={externalLinkIcon} /></Link></li>
			</ul>
		</div>
	)
}