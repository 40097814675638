import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

// import welcomeBannerShapesIcon from '../images/shapes-icon.png';

function JobsPage(props) {

	const [jobs, setJobs] = useState([]);
	const [jobsHaveOverdue, setJobsHaveOverdue] = useState(0);
	const [filterJobs, setFilterJobs] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(25);

	const getJobs = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-jobs/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setJobs(response.data.jobs.jobs);
				if(response.data.jobs.overdue_count) {
					setJobsHaveOverdue(1);
				}
			} else {
				alert('There was a problem retrieving the jobs')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	useEffect(() => {
		getJobs()
		setFilterJobs(['In Progress', 'Completed', 'Overdue'])
	}, []);

	const updateJobsFilter = (filter) => {
		setFilterJobs(filter)
		if(filter?.length === 3) {
			setRecordsPerPage(25);
		} else {
			setRecordsPerPage(999);
		}
	}

	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = jobs.slice(indexOfFirstRecord, indexOfLastRecord);
	const nPages = Math.ceil(jobs.length / recordsPerPage)

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const goToNextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="jobs" />
			<div className="appContent">

				<div className="appContentHeader">
					<h1>Jobs</h1>
					<p>All the stuff we’re doing for you, and all the stuff we’ve already done.</p>
				</div>

				<div className="jobsTableFilters">
					<Link to="#" className={filterJobs.includes('In Progress') && filterJobs.includes('Overdue') && filterJobs.includes('Completed') ? 'active' : ''} onClick={() => updateJobsFilter(['In Progress', 'Completed', 'Overdue'])}>Show all</Link>
					<Link to="#" className={filterJobs?.length === 1 && filterJobs.includes('Completed') ? 'active' : ''} onClick={() => updateJobsFilter(['Completed'])}>Completed only</Link>
					{jobsHaveOverdue ? (
					<Link to="#" className={filterJobs?.length === 1 && filterJobs.includes('Overdue') ? 'active' : ''} onClick={() => updateJobsFilter(['Overdue'])}>Overdue only</Link>
					):('')}
					<Link to="#" className={filterJobs?.length === 1 && filterJobs.includes('In Progress') ? 'active' : ''} onClick={() => updateJobsFilter(['In Progress'])}>In progress only</Link>
				</div>

				<div className="jobsTable">
					<table>
						<thead>
							<th className="jobTableCellJob">Job</th>
							<th className="jobTableCellDescription">Description</th>
							<th className="jobTableCellProgress">Progress</th>
							<th className="jobTableCellStatus">Status</th>
							<th className="jobTableCellDateDue">Date due</th>
						</thead>
						<tbody>
						{jobs?.length > 0 ? (
						<>
							{currentRecords.map((job) => (
							<>
							{filterJobs.includes(''+job.jobProgress) ? (
							<>
							<tr>
								<td className="jobTableCellJob">{job.jobName}</td>
								<td className="jobTableCellDescription">{job.jobDescription}</td>
								<td className="jobTableCellProgress">
									{job.jobProgress === 'In Progress' ? (
									<span className="jobStatus jobStatusInProgress"><span></span>In Progress</span>
									) : job.jobProgress === 'Completed' ? (
									<span class="jobStatus jobStatusCompleted"><span></span>Completed</span>
									) : job.jobProgress === 'Overdue' ? (
									<span class="jobStatus jobStatusOverdue"><span></span>Overdue</span>
									) : ('')}
								</td>
								<td className="jobTableCellStatus">{job.jobStatus}</td>
								<td className="jobTableCellDateDue">{job.jobDueDate}</td>
							</tr>
							</>
							):('')}
							</>
							))}
						</>
						):(
						<>
						{jobs === 0 ? (
						<p className="noJobsFound">No jobs found for your current filters</p>
						):(
						<div className="loader">Loading</div>
						)}
						</>
						)}
						</tbody>	
					</table>
				</div>

				{pageNumbers?.length > 1 ? (
				<nav className="paginationWrapper">
					<ul className='pagination'>
						{pageNumbers.map(pgNumber => (
						<li key={pgNumber} 
						className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
							<Link onClick={() => setCurrentPage(pgNumber)}  
							className='page-link' 
							to='#'>{pgNumber}</Link>
						</li>
						))}
					</ul>
				</nav>
				):('')}

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default JobsPage