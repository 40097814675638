import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom'
import axios from 'axios';

import Header from '../components/Header'

import defaultCompanyPic from '../images/default-company-pic.jpg'
import iconTrash from '../images/icon-trash.png'
import twelveMonthExpectationIcon from '../images/12-month-expectation-icon.png'

function OnboardingPage(props) {

	const {onboardtoken} = useParams();
	const [onboardingStage, setOnboardingStage] = useState('team')
	const [loadOnboarding, setLoadOnboarding] = useState(0)
	const [primaryUsers, setPrimaryUsers] = useState([])
	const [companyDetails, setCompanyDetails] = useState([])

	useEffect(() => {
		getPrimaryUser()
	}, []);

	// Step 1 - Team Members
	const getPrimaryUser = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-onboard-users/'
		const formData = new FormData();
		formData.append('onboard_key', onboardtoken);
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setPrimaryUsers(response.data.primary_user)
				setCompanyDetails(response.data.company)
				setLoadOnboarding(1);
			} else {
				if(response.data.fail_reason === 'already_completed') {
					window.location.href = '/';
				} else {
					alert('There was a problem loading this onboarding, please get in touch')
				}
			}
		});
	}
	const [newTeamMemberFormFields, setNewTeamMemberFormFields] = useState([])
	const handleFormChange = (event, index) => {
		let data = [...newTeamMemberFormFields];
		data[index][event.target.name] = event.target.value;
		setNewTeamMemberFormFields(data);
	}
	const addTeamMemberFormFields = () => {
		let object = {
		  firstName: '',
		  lastName: '',
		  email: '',
		  mobilePhone: ''
		}
		setNewTeamMemberFormFields([...newTeamMemberFormFields, object])
		setTimeout(function() {
			var firstNameFields = document.getElementsByClassName("firstNameField")
			firstNameFields[firstNameFields.length-1].focus()
		}, 100);
	}
	const removeTeamMemberFormFields = (index) => {
		let data = [...newTeamMemberFormFields];
		data.splice(index, 1)
		setNewTeamMemberFormFields(data)
	}
	const submitOnboardingTeamMembers = (e) => {
		e.preventDefault();
		// console.log(newTeamMemberFormFields)
		const url = process.env.REACT_APP_REST_URL+'/onboard-new-users/'
		const formData = new FormData();
		formData.append('onboard_key', onboardtoken);
		formData.append('team_members', JSON.stringify(newTeamMemberFormFields));
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setOnboardingStage('upload')
			} else {
				alert('There was a problem adding those team members, please get in touch')
			}
		});
	}


	// Step 2 - Uploads
	const [filesUploaded, setFilesUploaded] = useState([])
	const [fileUploading, setFileUploading] = useState([])
	const [file, setFile] = useState()
	const [fileToDelete, setFileToDelete] = useState()
	const [showRemoveFileConfirmation, setShowRemoveFileConfirmation] = useState(0)
	const [showChooseFileCategoryError, setShowChooseFileCategoryError] = useState(0)
	const [fileDescription, setFileDescription] = useState('')
	const [fileCategory, setFileCategory] = useState('')
	const [onboardingUploadStep, setOnboardingUploadStep] = useState(1)
	const handleUploadFilePreview = (e) => {
		console.log(e);
		setFile(e.target.files[0])
	}
	const handleUploadFile = (e) => {
		// console.log(e);
		e.preventDefault()
		if(!fileCategory) {
			setShowChooseFileCategoryError(1);
		} else {
			setShowChooseFileCategoryError(0);
			setFileUploading(1);
			const url = process.env.REACT_APP_REST_URL+'/onboard-upload/';
			const formData = new FormData();
			formData.append('file_to_upload', file);
			formData.append('file_category', fileCategory);
			formData.append('file_description', fileDescription);
			formData.append('onboard_key', onboardtoken);
			const config = {
			  headers: {
			    'content-type': 'multipart/form-data',
			  },
			};
			axios.post(url, formData, config).then((response) => {
				console.log(response.data);
				if(response.data.success === 1) {
					let object = {
						file_name: response.data.uploaded_file_name,
						file_id: response.data.uploaded_file_id
					}
					setFilesUploaded([...filesUploaded, object])
				} else {
					alert('There was a problem uploading this file')
				}
				setOnboardingUploadStep(1)
				setFileUploading(0)
				setFile('')
			});
		}
	}
	const removeFileFromUpload = (e) => {
		setFile('')
	}
	const removeFileUploadedConfirm = (index) => {
		setFileToDelete(index)
		setShowRemoveFileConfirmation(1)
	}
	const hideRemoveFileConfirmationModal = (e) => {
		e.preventDefault()
		setFileToDelete('')
		setShowRemoveFileConfirmation(0)
	}
	const removeFileUploaded = (e) => {
		e.preventDefault();
		setShowRemoveFileConfirmation(0)
		let thisFile = filesUploaded[fileToDelete]
		// console.log(thisFile)

		const url = process.env.REACT_APP_REST_URL+'/onboard-remove/';
		const formData = new FormData();
		formData.append('file_id', thisFile.file_id);
		formData.append('onboard_key', onboardtoken);
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response.data);
			if(response.data.success === 1) {
				let data = [...filesUploaded];
				data.splice(fileToDelete, 1)
				setFilesUploaded(data)
				setFileToDelete('')
			} else {
				alert('There was a problem removing this file')
				setFileToDelete('')
			}
		});
	}


	// Step 3 - Questionnaire
	const [hotDrinks, setHotDrinks] = useState('');
	const hotDrinksOptions = ['Normal tea with milk', 'Fruity tea for me please!', 'Coffee', 'Just water thanks']
	const [hotDrinksExtras, setHotDrinksExtras] = useState([]);
	const hotDrinksExtrasOptions = ['Cows Milk?', 'Oat milk?', 'Sugar?']
	const [tippleOfChoice, setTippleOfChoice] = useState('');
	const tippleOfChoiceOptions = ['Lager / beer', 'Craft ales', 'Red wine', 'White wine', 'Rose wine', 'Gin', 'Whiskey', 'Vodka', 'I don’t drink alcohol']
	const [twelveMonthExpectation, setTwelveMonthExpectation] = useState('');
	const updateHotDrinksExtras = (e) => {
		if(e.target.checked) {
			setHotDrinksExtras([...hotDrinksExtras, e.target.value]);
		} else {
			const newList = hotDrinksExtras.filter((item) => item !== e.target.value);
			setHotDrinksExtras(newList);
		}
	}
	const submitOnboardingQuestionnaire = (e) => {
		e.preventDefault();
		const url = process.env.REACT_APP_REST_URL+'/onboard-questionnaire/'
		const formData = new FormData();
		formData.append('onboard_key', onboardtoken);
		formData.append('hotDrinks', hotDrinks);
		formData.append('hotDrinksExtras', JSON.stringify(hotDrinksExtras));
		formData.append('tippleOfChoice', tippleOfChoice);
		formData.append('twelveMonthExpectation', twelveMonthExpectation);
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				window.location.href = '/';
			} else {
				alert('There was a problem submitting your answers, please get in touch')
			}
		});
	}


	// const removeFileUploaded = (fileId) => {
	// 	// setFileToDelete(fileId)
	// 	setTimeout(function() {
	// 		console.log(fileToDelete)
	// 	}, 100);
	// 	const url = 'https://www.devupnorth.co.uk/shapes-upload-test/remove.php';
	// 	const formData = new FormData();
	// 	formData.append('file_id', fileId);
	// 	const config = {};
	// 	axios.post(url, formData, config).then((response) => {
	// 		console.log(response.data);
	// 		if(response.data.success === 1) {
	// 			let object = {
	// 				file_name: response.data.uploaded_file_name,
	// 				file_id: response.data.uploaded_file_id
	// 			}
	// 			setFilesUploaded([...filesUploaded, object])
	// 		} else {
	// 			alert('There was a problem uploading this file')
	// 		}
	// 		setOnboardingUploadStep(1)
	// 		setFileUploading(0)
	// 		setFile('')
	// 	});
	// }

	return (
		<>
		{loadOnboarding ? (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		<div className="appOnboardingWrapper">
			<div className="container">
			{onboardingStage === 'team' ? (

				<>
				<div className="onboardingHeader">
					<h1>Hey {primaryUsers.firstName}, setup your Shapes experience here</h1>
				</div>
				<form onSubmit={submitOnboardingTeamMembers}>
					<div className="onboardingAddTeamMembers">
						<header>
							<div className="onboardingAddTeamMembersCompanyName">
								{companyDetails.company_logo ? (
								<div className="companyLogo"><img src={"data:image/png;base64,"+companyDetails.company_logo} /></div>
								):(
								<div className="companyLogo"><img src={defaultCompanyPic} /></div>
								)}
								{companyDetails.company_name}
							</div>
							<p>Add all your team who will need access to the portal here. They will get sent invites to the portal. <br/>You can add more users later on.</p>
						</header>
						<div className="onboardingAddTeamMembersTable">
							<table>
								<thead>
									<tr>
										<th>First name</th>
										<th>Surname</th>
										<th className="cellEmail">Email</th>
										<th className="cellMobile">Mobile phone</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{primaryUsers.firstName}</td>
										<td>{primaryUsers.lastName}</td>
										<td className="cellEmail">{primaryUsers.email}</td>
										<td>{primaryUsers.mobilePhone}</td>
									</tr>
									{newTeamMemberFormFields.map((form, index) => {
										return (
										<tr key={index}>
											<td className="hasInput">
											<input
											name="firstName"
											placeholder="First name"
											className="firstNameField"
											onChange={event => handleFormChange(event, index)}
											value={form.firstName}
											/>
											</td>
											<td className="hasInput">
											<input
											name="lastName"
											placeholder="Surname"
											className="lastNameField"
											onChange={event => handleFormChange(event, index)}
											value={form.lastName}
											/>
											</td>
											<td className="cellEmail hasInput">
											<input
											name="email"
											placeholder="Email"
											className="emailField"
											onChange={event => handleFormChange(event, index)}
											value={form.email}
											/>
											</td>
											<td className="cellMobile hasInput">
											<input
											name="mobilePhone"
											placeholder="Mobile phone"
											className="mobilePhoneField"
											onChange={event => handleFormChange(event, index)}
											value={form.mobilePhone}
											/>
											<button className="button removeNewTeamMemberLink" onClick={() => removeTeamMemberFormFields(index)}>x</button>
											</td>
										</tr>
										)
									})}
									<tr>
										<td colspan="4">
											<Link className="addNewTeamMemberLink" onClick={addTeamMemberFormFields}>+ add another</Link>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="formSubmit">
						<button onClick={submitOnboardingTeamMembers} className="button">Next</button>
					</div>
				</form>
				</>

			) : onboardingStage === 'upload' ? (

				<>
				<div className="onboardingHeader">
					<h1>Let’s upload some key documents</h1>
					<p>Please upload any document you think is important to us - this can be anything from brand documents to latest accounts</p>
				</div>
				<div className={filesUploaded?.length > 0 ? ( "onboardingUploadHasUploaded" ):( "onboardingUploadNotUploaded" )}>
					<div className="onboardingUpload">
						<form onSubmit={handleUploadFile} className="onboardingUploader">
							{onboardingUploadStep === 1 ? (
							<div className="onboardingUploaderStep1">
								<div className="onboardingUploaderBox">
									<span className="onboardingUploaderContent">
										<span class="onboardingUploaderContentIcon">+</span>
										<h5>Upload a{filesUploaded?.length > 0 ? ( "nother" ):('')} document</h5>
										<p>upload a document, give it a tag and a description</p>
									</span>
									<input
									type="file"
									name="fileToUpload"
									className="fileToUploadField"
									onChange={event => handleUploadFilePreview(event)}
									/>
									{file ? (
										<div className="fileSelected">{file.name}<Link onClick={removeFileFromUpload}><img src={iconTrash} /></Link></div>
									):([])}
								</div>
								<div className="formSubmit">
									{file ? (
									<Link onClick={() => setOnboardingUploadStep(2)} className="button">Next</Link>
									):([])}
									{filesUploaded?.length === 0 ? (
									<>
									<div className="clearfix"></div>
									<Link className="skipLink" onClick={() => setOnboardingStage('questionnaireA')} >Skip</Link>
									</>
									):('')}
								</div>
							</div>
							) : onboardingUploadStep === 2 ? (
							<div className="onboardingUploaderStep2">
								<div className="onboardingUploadDescriptionBox">
									<span className="onboardingLoader"><span className="onboardingLoaderHalf"></span></span>
									<h5>Give the file a description</h5>
									<textarea
									name="fileDescription"
									className="fileDescriptionField"
									placeholder="This description can be anything that helps explain the context of the document"
									onChange={event => setFileDescription(event.target.value)}
									></textarea>
								</div>
								<div className="formSubmit">
									<Link onClick={() => setOnboardingUploadStep(3)} className="button">Next</Link>
								</div>
							</div>
							) : onboardingUploadStep === 3 ? (
							<div className="onboardingUploaderStep3">
								<div className="onboardingUploadCategoryBox">
									{showChooseFileCategoryError ? (
									<>
									<div className="errorMessage"><span class="errorMessageIcon">!</span>Please choose a category.</div>
									</>
									):('')}
									{fileUploading === 1 ? (
									<div className="onboardingUploadUploading">
										<div className="loader"></div>
										Uploading file. Please wait.
									</div>
									):('')}
									<span className="onboardingLoader"><span className="onboardingLoaderThreeQuarter"></span></span>
									<h5>What category is this file?</h5>
									<div className="formCheckboxes onboardingUploadCategoryList">
										{primaryUsers.uploadCategories.map((uploadCategory, index) => (
										<div className="formCheckbox">
											<input 
											type="radio" 
								            id={`onboardingUploadCategoryListItem-${index}`}
											onChange={event => setFileCategory(event.target.value)}
											value={uploadCategory} 
											checked={fileCategory === uploadCategory}
											/>
											<label for={`onboardingUploadCategoryListItem-${index}`}>{uploadCategory}</label>
										</div>
										))}
									</div>
								</div>
								{fileUploading !== 1 ? (
								<button onClick={handleUploadFile} className="button">Finish</button>
								):('')}
							</div>
							) : ('')}
						</form>
					</div>
					{filesUploaded?.length > 0 ? (
					<div className="onboardingUploaded">
						<h4>Uploaded</h4>
						{filesUploaded.map((fileUploaded, index) => (
						<div className="onboardingUploadedFile">
							{fileUploaded.file_name} <Link to="#" onClick={(e) => removeFileUploadedConfirm(index)}><img src={iconTrash} /></Link>
						</div>
						))}
					</div>
					) : ('')}
				</div>
				{filesUploaded?.length > 0 ? (
				<div className="onboardingFinalStep">
					<Link className="button buttonBordered" onClick={() => setOnboardingStage('questionnaireA')} >Final step ></Link>
				</div>
				) : ('')}
				</>

			) : onboardingStage === 'questionnaireA' ? (

				<>
				<div className="onboardingHeader">
					<h1>A little about you</h1>
					<p>We’d love to get to know you a little more.<br/>Your answers here will help us add a little extra shape to our journey together! </p>
				</div>
				<div className="onboardingQuestionnaireForm">
					<div className="onboardingQuestionnaireBox">
						<h4>Hot drinks?</h4>
						<div className="formCheckboxes onboardingQuestionnaireHotDrinks">
						{hotDrinksOptions.map((hotDrinksOption, index) => (
							<div className="formCheckbox">
								<input 
								type="radio" 
								id={`hotDrinkOptions-${index}`}
								onChange={(e) => setHotDrinks(e.target.value)}
								value={hotDrinksOption} 
								checked={hotDrinks === hotDrinksOption}
								/>
								<label for={`hotDrinkOptions-${index}`}>{hotDrinksOption}</label>
							</div>
						))}
						<h5>What about the extras with your choice?</h5>
						{hotDrinksExtrasOptions.map((hotDrinksExtrasOption, index) => (
							<div className="formCheckbox">
								<input 
								type="checkbox" 
								id={`hotDrinkExtrasOptions-${index}`}
								onChange={updateHotDrinksExtras}
								value={hotDrinksExtrasOption} 
								checked={hotDrinksExtras.includes(''+hotDrinksExtrasOption)}
								/>
								<label for={`hotDrinkExtrasOptions-${index}`}>{hotDrinksExtrasOption}</label>
							</div>
						))}
						</div>
					</div>
					<div className="formSubmit">
						<button onClick={() => setOnboardingStage('questionnaireB')} className="button">Next</button>
					</div>
				</div>
				</>

			) : onboardingStage === 'questionnaireB' ? (

				<>
				<div className="onboardingHeader">
					<h1>A little about you</h1>
					<p>We’d love to get to know you a little more.<br/>Your answers here will help us add a little extra shape to our journey together! </p>
				</div>
				<div className="onboardingQuestionnaireForm">
					<div className="onboardingQuestionnaireBox">
						<h4>What’s your tipple of choice?</h4>
						<div className="formCheckboxes onboardingQuestionnaireTippleOfChoice">
						{tippleOfChoiceOptions.map((tippleOfChoiceOption, index) => (
							<>
							{tippleOfChoiceOption === 'Gin' ? ( 
							<>
							<h5>...or something stronger?</h5>
							</>
							):('')}
							<div className="formCheckbox">
								<input 
								type="radio" 
								id={`tippleOfChoiceOptions-${index}`}
								onChange={(e) => setTippleOfChoice(e.target.value)}
								value={tippleOfChoiceOption} 
								checked={tippleOfChoice === tippleOfChoiceOption}
								/>
								<label for={`tippleOfChoiceOptions-${index}`}>{tippleOfChoiceOption}</label>
							</div>
							</>
						))}
						</div>
					</div>
					<div className="formSubmit">
						<button onClick={() => setOnboardingStage('questionnaireC')} className="button">Next</button>
					</div>
				</div>
				</>

			) : onboardingStage === 'questionnaireC' ? (

				<>
				<div className="onboardingHeader">
					<h1>Imagine the future...</h1>
					<p>We’re big fan’s of vision and goal setting, and would love to capture something from you at this early stage.</p>
				</div>
				<form onSubmit={submitOnboardingQuestionnaire} className="onboardingQuestionnaireForm">
					<div className="onboardingQuestionnaireBox onboardingQuestionnaireBoxTwelveMonthExpectation">
						<div className="onboardingQuote">
							<img src={twelveMonthExpectationIcon} />
							<p>“I’m so happy we chose Shapes”</p>
						</div>
						<h4>If we look back and celebrate together in 12 months time, what are we celebrating?</h4>
						<textarea
						name="twelveMonthExpectation"
						className="twelveMonthExpectationField"
						placeholder="what is your hope and impact that Shapes can make for you and your business/organisation? "
						onChange={event => setTwelveMonthExpectation(event.target.value)}
						></textarea>
					</div>
					<div className="formSubmit">
						<button onClick={submitOnboardingQuestionnaire} className="button buttonBordered">Finish & Login</button>
					</div>
				</form>
				</>

			):([])}
			</div>
		</div>
		</>
		):('')}

		{showRemoveFileConfirmation ? (
		<>
		<div className="modalBack active"></div>
		<div className="modalWindow active">
			<div className="modalWindowInner animated fadeInUp">
				<Link to="#" className="modalWindowClose" onClick={hideRemoveFileConfirmationModal}></Link>
				<div className="modalWindowContent modalTeamMember">
					<div className="modalTeamMemberHeader">
						<h3>Are you sure?</h3>
						<p>Are you sure you would like to remove this file?</p>
						<div className="modalConfirmButtons">
							<Link to="#" onClick={removeFileUploaded} className="button">Confirm delete</Link>
							<Link to="#" onClick={hideRemoveFileConfirmationModal} className="button buttonBordered">Keep file</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
		) : ('')}
		</>
	);
}

export default OnboardingPage