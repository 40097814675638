import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import axios from 'axios';

import LoginPage from './pages/Login'
import OnboardingPage from './pages/Onboarding'

import DashboardPage from './pages/Dashboard'
import JobsPage from './pages/Jobs'
import DocumentsPage from './pages/Documents'
import DocumentsFolderPage from './pages/DocumentsFolder'
import AppsPage from './pages/Apps'
import ReferralsPage from './pages/Referrals'

import './css/main.css';

function App() {
  
	const [isLoggedIn, setIsLoggedIn] = useState(0);
	const [userDetails, setUserDetails] = useState([]);
	const [showLoginForm, setShowLoginForm] = useState(0);
	const [showOnboarding, setShowOnboarding] = useState(0);
	const [loginError, setLoginError] = useState([]);
	const [jwt, setJwt] = useState('');

	useEffect(() => {
		// setIsLoggedIn(0);
		if(!isLoggedIn) {
			if(window.location.pathname.search('/onboard') > -1) {
				setShowLoginForm(0);
				setShowOnboarding(1);
			} else {
				setShowLoginForm(1);
				setShowOnboarding(0);
			}
		}
	});

	function setJwtCode(jwtCode) {
		setJwt(jwtCode)
		const url = process.env.REACT_APP_REST_URL+'/get-user/&JWT='+jwtCode
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setUserDetails(response.data);
			} else {
				alert('There was a problem retrieving your profile')
			}
		});
		setIsLoggedIn(1)
		setShowLoginForm(0)
	}

	const logUserOut = () => {
		setJwt('')
		setIsLoggedIn(0)
		setShowLoginForm(1)
		window.location.href = '/'
	}

  return (
    <>
    {isLoggedIn ? (
    <div className="appContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<DashboardPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
          <Route path="/jobs" element={<JobsPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
          <Route path="/documents" element={<DocumentsPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
          <Route path="/documents/:folder" element={<DocumentsFolderPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
          <Route path="/apps" element={<AppsPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
          <Route path="/referrals" element={<ReferralsPage jwt={jwt} logUserOut={logUserOut} isLoggedIn={isLoggedIn} userDetails={userDetails} />} />
        </Routes>
      </BrowserRouter>
    </div>
    ) : showOnboarding ? (
    <div className="appContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<LoginPage setJwtCode={setJwtCode} isLoggedIn={isLoggedIn} loginError={loginError} />} />
          <Route path="/onboard/:onboardtoken" element={<OnboardingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
    ) : showLoginForm ? (
    <div className="appContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<LoginPage setJwtCode={setJwtCode} isLoggedIn={isLoggedIn} loginError={loginError} />} />
        </Routes>
      </BrowserRouter>
    </div>
    ):('')}
    </>
  );

}

export default App;