import { Link } from "react-router-dom";

export default function Footer(props) {
	return (
	<>
	<div className={"appFooter appFooter-loggedIn-" + props.isLoggedIn}>
		<div className="container">
			<p>© Copyright We Are Shapes Ltd {(new Date().getFullYear())}<br/>‘Shapes’ is a trading name of We are Shapes Limited, a company registered in England & Wales - company number 14881487</p>
		</div>
	</div>
	</>
	)
}