import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

function DocumentsPage(props) {

	const [driveDocuments, setDriveDocuments] = useState([]);

	const getDocuments = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-documents/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setDriveDocuments(response.data.documents);
			} else {
				alert('There was a problem retrieving the documents')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	useEffect(() => {
		getDocuments();
	}, []);

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="documents" />
			<div className="appContent">

				<div className="appContentHeader">
					<h1>Documents</h1>
					<p>From year end to our agreements, all your documents are here</p>
				</div>

				{driveDocuments?.length > 0 ? (
				<div className="documentFolders">
				{driveDocuments.map((driveDocument) => (
					<Link to={"/documents/"+driveDocument.folderName} className="documentFolder">
						<>
						{driveDocument.folderName === 'Personal Tax Returns' ? (
						<>
						<svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M0.637445 6.32967L14.8718 23.2112L30.7583 7.32471C22.869 -1.97085 9.38345 -2.41634 0.637445 6.32967Z" fill="#FFA59B"/>
						</svg>
						</>
						) : driveDocument.folderName === 'Company Tax Returns' ? (
						<>
						<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4819 14.8663L13.6406 28.7077L0.158794 13.8413L14.0001 -2.75704e-05L27.4819 14.8663Z" fill="#101010"/>
						</svg>
						</>
						) : driveDocument.folderName === 'Management Accounts' ? (
						<>
						<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12.5" cy="12.5" r="12.5" fill="#FFE8CE"/>
						</svg>
						</>
						) : driveDocument.folderName === 'Year End Accounts' ? (
						<>
						<svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M0.637445 6.32967L14.8718 23.2112L30.7583 7.32471C22.869 -1.97085 9.38345 -2.41634 0.637445 6.32967Z" fill="#2B3B50"/>
						</svg>
						</>
						) : driveDocument.folderName === 'Shapes Documents' ? (
						<>
						<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4819 14.8662L13.6406 28.7076L0.158794 13.8412L14.0001 -0.000149641L27.4819 14.8662Z" fill="#FFA59B"/>
						</svg>
						</>
						) : driveDocument.folderName === 'Company Records' ? (
						<>
						<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4819 14.8663L13.6406 28.7077L0.158794 13.8413L14.0001 -2.75704e-05L27.4819 14.8663Z" fill="#101010"/>
						</svg>
						</>
						) : ('')}
						</>
						<h4>{driveDocument.folderName}</h4>
						{driveDocument.folderName === 'Personal Tax Returns' ? (
						<span className="documentFolderDescription">Personal tax returns are all found in here</span>
						) : driveDocument.folderName === 'Company Tax Returns' ? (
						<span className="documentFolderDescription">Company filing returns are all found in here</span>
						) : driveDocument.folderName === 'Management Accounts' ? (
						<span className="documentFolderDescription">Any and all tax returns we’ve done for you</span>
						) : driveDocument.folderName === 'Year End Accounts' ? (
						<span className="documentFolderDescription">View all the years in one handy place</span>
						) : driveDocument.folderName === 'Shapes Documents' ? (
						<span className="documentFolderDescription">Engagement letters, contracts and other files</span>
						) : driveDocument.folderName === 'Company Records' ? (
						<span className="documentFolderDescription">Companies House, minutes & more</span>
						) : ('')}
					</Link>
				))}
				</div>
				):(
				<div className="loader">Loading</div>
				)}

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default DocumentsPage