import React, { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

import iconDownload from '../images/icon-download.png'

function DocumentsFolderPage(props) {

	const { folder } = useParams();
	const [driveDocuments, setDriveDocuments] = useState([]);
	const [fileGeneratedDownloadLink, setFileGeneratedDownloadLink] = useState('');
	const [fileGeneratedDownloadTitle, setFileGeneratedDownloadTitle] = useState('');

	const getDocuments = async () => {
		const url = process.env.REACT_APP_REST_URL+'/get-documents/&JWT='+props.jwt
		const formData = new FormData();
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setDriveDocuments(response.data.documents);
			} else {
				alert('There was a problem retrieving the documents')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	useEffect(() => {
		getDocuments();
	}, []);

	const downloadFile = (fileId, fileName) => {
		setFileGeneratedDownloadTitle(fileName)
		const url = process.env.REACT_APP_REST_URL+'/request-download/&JWT='+props.jwt
		const formData = new FormData()
		formData.append('fileId', fileId)
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			setFileGeneratedDownloadLink(response.data.link+'&JWT='+props.jwt);
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}

	function downloadFileClicked() {
		setFileGeneratedDownloadLink('')
		setFileGeneratedDownloadTitle('')
	}

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} />
		<div className="appPanel">
			<Sidebar isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} userDetails={props.userDetails} currentPage="documents" />
			<div className="appContent">

				<div className="appContentHeader">
					<h1>{folder}</h1>
					<div className="appBreadcrumb">
						<Link to="/documents">Documents</Link> > {folder}
					</div>
					<p>From year end to our agreements, all your documents are here</p>
				</div>

				{fileGeneratedDownloadTitle ? (
				<>
				<div className="modalBack active" onClick={() => downloadFileClicked()}></div>
				<div className="modalWindow active">
					<div className="modalWindowInner animated fadeInUp">
						<Link to="#" className="modalWindowClose" onClick={() => downloadFileClicked()}></Link>
						<div className="modalWindowContent modalDownload">
							<div className="modalDownloadHeader">
								<h3>{fileGeneratedDownloadTitle}</h3>
							</div>
							<div className="modalDownloadContent">
								{fileGeneratedDownloadLink ? (
								<>
								<p>Your file is ready to download.</p>
								<Link to={fileGeneratedDownloadLink} onClick={(e) => downloadFileClicked()} className="button" target="_blank">Download now</Link>
								</>
								):(
								<>
								<p>Please wait while we generate your download</p>
								<div className="loader"></div>
								</>
								)}
							</div>
						</div>
					</div>
				</div>
				</>
				):('')}

				<div className="documentsTable">
					<table>
						<thead>
							<th className="documentsTableCellTitle">Title</th>
							<th className="documentsTableCellDescription">Description</th>
							<th className="documentsTableCellDateAdded">Date added</th>
							<th className="documentsTableCellDownload"> </th>
						</thead>
						<tbody>
						{driveDocuments?.length > 0 ? (
						<>
						{driveDocuments.map((driveFolder) => (
						<>
							{driveFolder.folderName === folder ? (
							<>
								{driveFolder.files?.length > 0 ? (
								<>
								{driveFolder.files.map((driveDocument) => (
								<>
								<tr>
									<td className="documentsTableCellTitle">{driveDocument.fileName}</td>
									<td className="documentsTableCellDescription">{driveDocument.fileDescription}</td>
									<td className="documentsTableCellDateAdded">{driveDocument.fileDate}</td>
									<td className="documentsTableCellDownload"><Link to="#" onClick={() => downloadFile(driveDocument.fileId, driveDocument.fileName)}><img src={iconDownload} /></Link></td>
								</tr>
								</>
								))}
								</>
								):(
								<p className="noDocumentsFound">There are no documents currently in this folder.</p>
								)}
							</>
							):('')}
						</>
						))}
						</>
						):(
							<div className="loader">Loading</div>
						)}
						</tbody>	
					</table>
				</div>

			</div>
		</div>
		<Footer isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		</>
	);
}

export default DocumentsFolderPage