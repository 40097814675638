import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import Header from '../components/Header'

function LoginPage(props) {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState("");
	const [authenticatorQrCode, setAuthenticatorQrCode] = useState("");
	const [otp1, setOtp1] = useState("");
	const [otp2, setOtp2] = useState("");
	const [otp3, setOtp3] = useState("");
	const [otp4, setOtp4] = useState("");
	const [otp5, setOtp5] = useState("");
	const [otp6, setOtp6] = useState("");
	const [otp, setOtp] = useState("");
	const [loginStage, setLoginStage] = useState(0);

	function handleLoginStageA(e) {
		e.preventDefault();
		const url = process.env.REACT_APP_REST_URL+'/login-set-passcode/'
		const formData = new FormData();
		formData.append('email', email);
		formData.append('password', password);
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				setLoginError(0)
				setLoginStage(1)
				setTimeout(function() {
					document.getElementsByClassName("otp1")[0].focus()
				}, 100);
			} else {
				if(response.data.fail_reason === 'authenticator_needs_activating') {
					setAuthenticatorQrCode(response.data.authenticator_image)
					setLoginStage(1)
				} else {
					setLoginError('ERROR')
				}
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
	}
	function handleLoginStageB(e) {
		e.preventDefault();
		const url = process.env.REACT_APP_REST_URL+'/login-check-passcode/'
		const formData = new FormData();
		formData.append('email', email);
		formData.append('password', password);
		formData.append('passcode', otp1+''+otp2+''+otp3+''+otp4+''+otp5+''+otp6);
		const config = {
		  headers: {
		    'content-type': 'application/json',
		  },
		};
		axios.post(url, formData, config).then((response) => {
			console.log(response);
			console.log(response.data);
			if(response.data.success === 1) {
				// setLoginError(0)
				// setLoginStage(1)
				props.setJwtCode(response.data.jwt)
			} else {
				setLoginError('ERROROTP')
			}
		}).catch(function (error) {
			if(error.response.status === 403) {
				props.logUserOut();
			}
		});
		// setLoginStage(2);
		// props.onLogin({ email, password });
	}

	function changeOpt1(e) {
		setOtp1(e.target.value);
		if(document.getElementsByClassName("otp2")[0].value == '') {
			document.getElementsByClassName("otp2")[0].focus()
		}
	}
	function changeOpt2(e) {
		setOtp2(e.target.value);
		if(document.getElementsByClassName("otp3")[0].value == '') {
			document.getElementsByClassName("otp3")[0].focus()
		}
	}
	function changeOpt3(e) {
		setOtp3(e.target.value);
		if(document.getElementsByClassName("otp4")[0].value == '') {
			document.getElementsByClassName("otp4")[0].focus()
		}
	}
	function changeOpt4(e) {
		setOtp4(e.target.value);
		if(document.getElementsByClassName("otp5")[0].value == '') {
			document.getElementsByClassName("otp5")[0].focus()
		}
	}
	function changeOpt5(e) {
		setOtp5(e.target.value);
		if(document.getElementsByClassName("otp6")[0].value == '') {
			document.getElementsByClassName("otp6")[0].focus()
		}
	}
	function changeOpt6(e) {
		setOtp6(e.target.value);
	}

	return (
		<>
		<Header isLoggedIn={props.isLoggedIn} logUserOut={props.logUserOut} />
		<div className="appLoginWrapper">
			<div className="container">
			{loginStage === 0 ? (
				<>
				<h1>Login to your Shapes portal</h1>
				<div className="appLoginBox">
					<div className="appLoginBoxHeader">
						<h4>Login</h4>
					</div>
					<div className="appLoginBoxContent">
						{loginError == 'ERROR' ? (
						<>
						<div id="failedLogin"><span class="failedLoginIcon">!</span>That email/password combo doesn’t seem to exist</div>
						</>
						) : (
						<>
						</>
						)}
						<form method="post" className="appLoginBoxForm" onSubmit={handleLoginStageA}>
							<div className="formGroup">
								<label className="formLabel"><span>email</span></label>
								<input
								type="text"
								placeholder="" 
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="formGroup">
								<label className="formLabel"><span>password</span></label>
								<input
								type="password"
								placeholder="" 
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="formSubmit">
								<input type="submit" value="login" />
							</div>
							<p>forgotten your email / password?<br/><Link to="mailto:hello@shapes.team">contact us here</Link></p>
						</form>
					</div>
				</div>
				</>
			) : loginStage === 1 ? (

				<>
				<h1>Nearly there</h1>
				<div className="appLoginBox">
					<div className="appLoginBoxHeader">
						<h4>Login</h4>
					</div>
					<div className={authenticatorQrCode ? ( 'appLoginBoxContent appLoginBoxContentHasQrCode' ):( 'appLoginBoxContent' )}>
						{loginError == 'ERROROTP' ? (
						<>
						<div id="failedLogin"><span class="failedLoginIcon">!</span>Oops! That code didn’t work.</div>
						</>
						) : (
						<>
						</>
						)}
						{authenticatorQrCode ? (
						<div className="authenticatorQrCodeWrapper">
							<p>To make your account secure, we require two factor authentication through the Google Authenticator App. Please open your Google Authenticator and scan this QR code and then add the code that shows on your phone in the the field below.</p>
							<img src={"data:image/png;base64,"+authenticatorQrCode} />
						</div>
						):('')}
						<form method="post" className="appLoginBoxForm" onSubmit={handleLoginStageB}>
							<div className="formGroup formGroupHide">
								<label className="formLabel"><span>email</span></label>
								<input
								type="text"
								placeholder="" 
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="formGroup formGroupHide">
								<label className="formLabel"><span>password</span></label>
								<input
								type="password"
								placeholder="" 
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="formGroup formGroupOtp">
								<label className="formLabel"><span>enter the code from authenticator</span></label>
								<input
								type="number"
								placeholder="" 
								value={otp1}
								className="otp1" 
								max="9" 
								onChange={(e) => changeOpt1(e)}
								/><input
								type="number"
								placeholder="" 
								value={otp2}
								className="otp2" 
								max="9" 
								onChange={(e) => changeOpt2(e)}
								/><input
								type="number"
								placeholder="" 
								value={otp3}
								className="otp3" 
								max="9" 
								onChange={(e) => changeOpt3(e)}
								/><input
								type="number"
								placeholder="" 
								value={otp4}
								className="otp4" 
								max="9" 
								onChange={(e) => changeOpt4(e)}
								/><input
								type="number"
								placeholder="" 
								value={otp5}
								className="otp5" 
								max="9" 
								onChange={(e) => changeOpt5(e)}
								/><input
								type="number"
								placeholder="" 
								value={otp6}
								className="otp6" 
								max="9" 
								onChange={(e) => changeOpt6(e)}
								/>
							</div>
							<div className="formSubmit">
								<input type="submit" value="login" />
							</div>
							<p>problems with the code?<br/><Link to="mailto:hello@shapes.team">contact us here</Link></p>
						</form>
					</div>
				</div>
				</>

			) : (
				<>
				<h1>Wahoo!</h1>
				<div className="appLoginBox">
					<div className="appLoginBoxHeader">
						<h4>You’re in!</h4>
					</div>
					<div className="appLoginBoxContent appLoginBoxContentSuccess">
						<p>Lottie file here!</p>
						<div className="appLoginRedirectingMessage">We’re redirecting you to the portal!</div>
					</div>
				</div>
				</>
			)}
			</div>
		</div>
		</>
	);
}

export default LoginPage